import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../elements/sidebar/sidebar';

export default function MainLayout() {
  return (
    <div className="flex h-screen bg-dark-6">
      <Sidebar />
      <div className="w-full">
        <div className="sticky px-6 py-4 bg-white border-b border-gray-100 w-full">
          <h1 className="text-dark-5 text-xl font-bold">Dashboard</h1>
          <p className="text-dark-5 text-sm font-medium">Dashboard</p>
        </div>
        <div className="p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}



