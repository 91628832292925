import MainLayout from '../components/layouts/admin-layout/main-layout';
import { Beranda, Login, Counter, Survei, Caller, Kios, Portal, AdminBeranda, BankQuestion, BankQuestionTambah } from '../pages';

const routes = [
  {
    path: '/',
    key: 'portal',
    title: 'Portal',
    Element: <Portal />
  },
  {
    path: '/home',
    key: 'home',
    title: 'Home',
    Element: <Beranda />,
  },
  {
    path: '/survei',
    key: 'survei',
    title: 'Survei',
    Element: <Survei />
  },
  {
    path: '/counter',
    key: 'counter',
    title: 'Counter',
    Element: <Counter />
  },
  {
    path: '/login',
    key: 'login',
    title: 'Login',
    Element: <Login />
  },
  {
    path: '/caller',
    key: 'caller',
    title: 'Caller',
    Element: <Caller />
  },
  {
    path: '/kios',
    key: 'kios',
    title: 'Kios',
    Element: <Kios />
  },
  {
    path: '*',
    key: 'notfound',
    Element: <h1>notfound</h1>
  },
  {
    path: '/admin',
    key: 'admin',
    title: 'Home',
    Element: <MainLayout />,
    childrens: [
      {
        index: true,
        key: 'admin-beranda',
        title: 'Beranda',
        Element: <AdminBeranda />
      },
      {
        key: 'admin-bank-pertanyaan',
        path: '/admin/bank-pertanyaan',
        title: 'Bank Pertanyaan',
        Element: <BankQuestion />
      },
      {
        key: 'admin-bank-pertanyaan-tambah',
        path: '/admin/bank-pertanyaan/tambah',
        title: 'Tambah Bank Pertanyaan',
        Element: <BankQuestionTambah />
      },
    ]
  },
];

export default routes;
