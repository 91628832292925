import './App.css';
import AppRoutes from './routes/app-routes';
import AppProvider  from './context/AppProvider';

function App() {

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
