import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFullScreenHandle } from 'react-full-screen';


export const AppContext = createContext(null);

const AppProvider = ({ children, valueProps }) => {
  const fullscreen = useFullScreenHandle();
  // console.log(fullscreen);
  const value = { fullScreen: fullscreen, ...valueProps };
  // console.log(children);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.defaultProps = {
  valueProps: {},
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  valueProps: PropTypes.object,
};

export default AppProvider;

export const useGetAppCtx = () => {
  return useContext(AppContext);
};
