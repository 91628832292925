import { useEffect } from 'react';
import PropTypes from 'prop-types';

function WithTitle(props) {
  const { children, title } = props;

  useEffect(() => {
    document.title = title;
  }, []);

  return children;
}

WithTitle.defaultProps = {
  title: '',
};

WithTitle.propTypes = {
  title: PropTypes.string,
};

export default WithTitle;
