import { Route, Routes } from 'react-router-dom';
import { FullScreen } from 'react-full-screen';
import WithTitle from '../components/elements/with-title';
import { useGetAppCtx } from '../context/AppProvider';
import routes from './routes';

export function setTitle(title) {
  document.title = title;
}

export function middleware({ processingRoute }) {
  return processingRoute.map((route, key) => {
    if (route.childrens) {
      return (
        <Route element={route.Element} key={key} path={route.path}>
          {route.childrens.map((children, childrenKey) => {
            const Component = (
              <WithTitle title={children.title}>{children.Element}</WithTitle>
            );
            if (children.index) return <Route element={children.Element} index key={childrenKey} />;
            return <Route element={Component} key={childrenKey} path={children.path} />;
          })}
        </Route>
      );
    }

    const Component = (
      <WithTitle title={route.title}>{route.Element}</WithTitle>
    );

    return <Route element={Component} key={key} path={route.path} />;
  });
}

function AppRoutes() {
  const routing = middleware({ processingRoute: routes });
  const { fullScreen } = useGetAppCtx();

  return (
    <FullScreen handle={fullScreen}>
      <Routes>
        {routing}
      </Routes>

    </FullScreen>
  );
}

export default AppRoutes;
