import { lazy, Suspense } from 'react';
import LazyFallback from '../components/elements/lazy-fallback';

const Suspensed = (Element) => function suspense(props) {
  return (
    <Suspense fallback={<LazyFallback />}>
      <Element {...props} />
    </Suspense>
  );
};

export const Beranda = Suspensed(lazy(() => import('./beranda')));
export const Counter = Suspensed(lazy(() => import('./counter')));
export const Login = Suspensed(lazy(() => import('./login')));
export const Survei = Suspensed(lazy(() => import('./survei')));
export const Caller = Suspensed(lazy(() => import('./caller')));
export const Kios = Suspensed(lazy(() => import('./kios')));
export const Portal = Suspensed(lazy(() => import('./portal')));

// admin page
export const AdminBeranda = Suspensed(lazy(() => import('./admin/beranda')));
export const BankQuestion = Suspensed(lazy(() => import('./admin/survei/bank-question')));
export const BankQuestionTambah = Suspensed(lazy(() => import('./admin/survei/bank-question/tambah')));

