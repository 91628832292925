import React from 'react';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import {
  PowerIcon,
} from '@heroicons/react/24/solid';
import { ChevronDownIcon, UserGroupIcon, HomeIcon, UserIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export function Sidebar() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Card className="h-screen w-full max-w-[252px] p-4 bg-blue-4 sticky rounded-none text-white">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
        Sistem Antrian Terpadu
        </Typography>
      </div>
      <List className="text-sm">
        <Link to="/admin">
          <ListItem className="gap-4 hover:bg-blue-9">
            <ListItemPrefix>
              <HomeIcon className="h-5 w-5" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`}
            />
          }
        >
          <ListItem className="p-0 hover:bg-blue-9" selected={open === 1}>
            <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 gap-4">
              <ListItemPrefix>
                <UserGroupIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal text-sm">
                Manage Survey
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0  text-sm">
              <Link to="/admin/bank-pertanyaan" className="flex items-center">
                <ListItem className="gap-4 hover:bg-blue-9 pl-12">
                Bank Question
                </ListItem>
              </Link>
              <ListItem className="gap-4 hover:bg-blue-9 pl-12">
                Survey Form
              </ListItem>
              <ListItem className="gap-4 hover:bg-blue-9 pl-12">
                Survey List
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <ListItem className="gap-4 hover:bg-blue-9 text-sm">
          <ListItemPrefix>
            <UserIcon className="h-5 w-5" />
          </ListItemPrefix>
          Counter
        </ListItem>
        <Link to="/login">
          <ListItem className="gap-4 hover:bg-blue-9 text-sm">
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            Log Out
          </ListItem>
        </Link>
      </List>
    </Card>
  );
}
